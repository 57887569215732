import React, { useState, useEffect } from "react";

import { BsPencilSquare } from "react-icons/bs";

import { LinkContainer } from "react-router-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";

import { API } from "aws-amplify";

import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";

import "./Home.css";

export default function Home(){
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);

    const [notes, setNotes] = useState([]);

    useEffect(() => {
        async function onLoad(){
            if(isAuthenticated){
                try{
                    const notes = await loadNotes();
                    setNotes(notes);
                }catch(error){
                    onError(error);
                }
                setIsLoading(false);
            }else{
                return;
            }
        }

        onLoad();
    }, [isAuthenticated]);

    function loadNotes(){
        return API.get("notes", "/notes");
    }

    function renderNotesList(notes){
        return(
            <>
                <LinkContainer to="/notes/new">
                    <ListGroup.Item action 
                        className="py-3 text-nowrap text-truncate">
                        <BsPencilSquare size={16}/>
                        <span className="ml-2 font-weight-bold">
                            Create a new note
                        </span>
                    </ListGroup.Item>
                </LinkContainer>

                {notes.map(({ noteID, content, createdAt }) => (
                    <LinkContainer key={noteID} to={`/notes/${noteID}`}>
                        <ListGroup.Item action
                            className="py-3 text-nowrap text-truncate">
                                <span className="font-weight-bold">
                                    {content.trim().split("\n")[0]}
                                    <br /> 
                                    <span className="text-muted">
                                        Created: {new Date(createdAt).toLocaleString()}
                                    </span>
                                </span>
                        </ListGroup.Item>
                    </LinkContainer>
                ))}
            </>
        )
    }

    function renderLander(){
        return(
            <div className="lander">
                <h1>ZeroNote</h1>
                <p className="text-muted">A simple note-taking app</p>

                <Button 
                    variant="outline-success" 
                    href="/signup">
                    Sign Up
                </Button>{' '}

                <Button 
                    variant="outline-primary" 
                    href="/login">
                    Log In
                </Button>

            </div>
        )
    }

    function renderNotes(){
        return(
            <div className="notes">
                <h4 className="pb-3 mt-4 mb-3 border-bottom">Your Notes</h4>
                <ListGroup>{!isLoading && renderNotesList(notes)}</ListGroup>
            </div>
        );
    }

    return(
        <div className="Home">
            {isAuthenticated? renderNotes() : renderLander()}
        </div>
    );
}