import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";

import { Auth } from "aws-amplify";

import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import { useFormFields } from "../lib/hooksLib";

import "./Signup.css";

export default function Signup(){
    const nav = useNavigate();

    const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [newUser, setNewUser] = useState(null);

    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: "",
        confirmPassword: "",
        confirmationCode: "",
    });

    function validateForm(){
        return (
            fields.email.length > 0 &&
            fields.password.length > 0 &&
            fields.password === fields.confirmPassword
        );
    }

    function validateConfirmationForm(){
        return fields.confirmationCode.length > 0;
    }

    async function handleSubmit(event){
        event.preventDefault();

        setIsLoading(true);
        try{
            const newUser = await Auth.signUp({
                username: fields.email,
                password: fields.password,
            });
            setIsLoading(false);
            setNewUser(newUser);
        }catch(error){
            if(error.code === "UsernameExistsException"){
                const newUser = await Auth.resendSignUp(fields.email);
                setIsLoading(false);
                setNewUser(newUser);
            }else{
                onError(error);
                setIsLoading(false);
            }
        }
    }

    async function handleConfirmationSubmit(event){
        event.preventDefault();

        setIsLoading(true);
        try{
            await Auth.confirmSignUp(fields.email, fields.confirmationCode);
            await Auth.signIn(fields.email, fields.password);

            userHasAuthenticated(true);
            nav("/");
        }catch(error){
            onError(error);
            setIsLoading(false);
        }
    }

    function renderConfirmationForm(){
        return(
            <Form onSubmit={handleConfirmationSubmit}>
                <Form.Group size="lg" controlId="confirmationCode">
                    <Form.Label>Confirmation Code</Form.Label>
                    <Form.Control
                        autoFocus
                        type="tel"
                        value={fields.confirmationCode}
                        onChange={handleFieldChange}/>
                    <Form.Text muted>
                        Please check your email for the code.
                    </Form.Text>
                </Form.Group>

                <LoaderButton
                    block="true"
                    type="submit"
                    variant="outline-success"
                    isLoading={isLoading}
                    disabled={!validateConfirmationForm()}>
                    Verify
                </LoaderButton>
            </Form>
        );
    }
    
    function renderForm(){
        return(
            <Form onSubmit={handleSubmit}>
                <Form.Group size="lg" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        autoFocus
                        type="email"
                        value={fields.email}
                        onChange={handleFieldChange}/>
                </Form.Group>

                <Form.Group size="lg" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        value={fields.password}
                        onChange={handleFieldChange}/>
                </Form.Group>

                <Form.Group size="lg" controlId="confirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        type="password"
                        value={fields.confirmPassword}
                        onChange={handleFieldChange}/>
                </Form.Group>

                <div className="d-grid gap-2">
                    <LoaderButton
                        block="true"
                        type="submit"
                        variant="outline-success"
                        isLoading={isLoading}
                        disabled={!validateForm()}>
                        Sign Up
                    </LoaderButton>
                </div>
            </Form>
        );
    }

    return(
        <div className="Signup">
            {newUser === null ? renderForm() : renderConfirmationForm()}
        </div>
    );
}