import React, { useState } from 'react';
import { BsCheck } from 'react-icons/bs';

import { FormText, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import LoaderButton from '../components/LoaderButton';

import { Auth } from 'aws-amplify';

import { useFormFields } from '../lib/hooksLib';
import { onError } from '../lib/errorLib';

import './ResetPassword.css';

export default function ResetPassword(){
    const [fields, handleFieldChange] = useFormFields({
        code: "",
        email: "",
        password: "",
        confirmPassword: "",
    });
    const [codeSent, setCodeSent] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false);

    function validateCodeForm(){
        return fields.email.length > 0;
    }
    function validateResetForm(){
        return (
            fields.code.length > 0 &&
            fields.password.length > 0 &&
            fields.password === fields.confirmPassword
        );
    }
    async function handleSendCodeClick(event){
        event.preventDefault();

        setIsSendingCode(true);
        try{
            await Auth.forgotPassword(fields.email);
            setCodeSent(true);
        }catch(error){
            onError(error);
            setIsSendingCode(false);
        }
    }
    async function handleConfirmClick(event){
        event.preventDefault();

        setIsConfirming(true);
        try{
            await Auth.forgotPasswordSubmit(
                fields.email,
                fields.code,
                fields.password
            );
            setConfirmed(true);
        }catch(error){
            onError(error);
            setIsConfirming(false);
        }
    }

    function renderRequestCodeForm(){
        return(
            <form onSubmit={handleSendCodeClick}>
                <FormGroup controlId="email" size="lg">
                    <FormLabel>Email</FormLabel>
                    <FormControl
                        autoFocus
                        type="email"
                        value={fields.email}
                        onChange={handleFieldChange}/>
                </FormGroup>

                <LoaderButton
                    block
                    type="submit"
                    variant="outline-primary"
                    size="lg"
                    isLoading={isSendingCode}
                    disabled={!validateCodeForm()}>
                    Send Confirmation Code
                </LoaderButton>
            </form>
        );
    }

    function renderConfirmationForm(){
        return(
            <form onSubmit={handleConfirmClick}>
                <FormGroup controlId="code" size="lg">
                    <FormLabel>Confirmation Code</FormLabel>
                    <FormControl
                        autoFocus
                        type="tel"
                        value={fields.code}
                        onChange={handleFieldChange}/>
                    <FormText>Please check your email ({fields.email}) for the confirmation code.</FormText>
                </FormGroup>
                <hr/>
                <FormGroup controlId="password" size="lg">
                    <FormLabel>New Password</FormLabel>
                    <FormControl
                        type="password"
                        value={fields.password}
                        onChange={handleFieldChange}/>
                </FormGroup>
                <FormGroup controlId="confirmPassword" size="lg">
                    <FormLabel>Confirm Password</FormLabel>
                    <FormControl
                        type="password"
                        value={fields.confirmPassword}
                        onChange={handleFieldChange}/>
                </FormGroup>

                <LoaderButton
                    block
                    type="submit"
                    variant="outline-primary"
                    isLoading={isConfirming}
                    disabled={!validateResetForm()}>
                    Confirm
                </LoaderButton>
            </form>
        );
    }

    function renderSuccessMessage(){
        return(
            <div className="success">
                <BsCheck/>
                <p>Your password has been reset. Log in?</p>
                <Button 
                    variant="outline-primary" 
                    href="/login">
                    Log In
                </Button>
            </div>
        );
    }

    return(
        <div className="ResetPassword">
            {!codeSent
                ? renderRequestCodeForm()
                : !confirmed
                    ? renderConfirmationForm()
                    : renderSuccessMessage()}
        </div>
    )
}