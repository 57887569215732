import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { FormText, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";

import { Auth } from "aws-amplify";

import { onError } from "../lib/errorLib";
import { useFormFields } from "../lib/hooksLib";

import "./ChangeEmail.css";

export default function ChangeEmail(){
    const nav = useNavigate();

    const [codeSent, setCodeSent] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [currentEmail, setCurrentEmail] = useState("");

    const [fields, handleFieldChange] = useFormFields({
        code: "",
        email: ""
    });

    useEffect(() => {
        async function onLoad(){
            try{
                const currentUser = await Auth.currentAuthenticatedUser();
                const { attributes } = currentUser;
                setIsEmailVerified(attributes["email_verified"]);
                setCurrentEmail(attributes["email"]);
            }catch(error){
                onError(error);
            }
        }
        onLoad();
    });

    function validateEmailForm(){
        return fields.email.length > 0;
    }
    function validateConfirmForm(){
        return fields.code.length > 0;
    }

    async function updateEmail(event){
        event.preventDefault();

        setIsSendingCode(true);
        try{
            const currentUser = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(currentUser, { email: fields.email });
            await Auth.updateUserAttributes(currentUser, { email_verified: "false" });
            setCodeSent(true);
        }catch(error){
            onError(error);
            setIsSendingCode(false);
        }
    }

    async function verifyEmail(event){
        event.preventDefault();

        setIsSendingCode(true);
        try{
            await Auth.verifyCurrentUserAttribute("email");
            fields.email = currentEmail;
            setCodeSent(true);
        }catch(error){
            onError(error);
            setIsSendingCode(false);
        }
    }

    async function handleConfirmClick(event){
        event.preventDefault();

        setIsConfirming(true);
        try{
            const currentUser = await Auth.currentAuthenticatedUser();
            await Auth.verifyCurrentUserAttributeSubmit("email", fields.code);
            await Auth.updateUserAttributes(currentUser, { email_verified: "true" });
            nav("/settings");
        } catch(error){
            onError(error);
            setIsConfirming(false);
        }
    }

    function renderUpdateForm(){
        return(
            <form onSubmit={updateEmail}>
                <FormGroup bsSize="large" controlId="email">
                    <FormLabel>Current Email</FormLabel>
                    <FormControl
                        readOnly
                        type="email"
                        placeholder={currentEmail}
                    />
                </FormGroup>
                <FormGroup bsSize="large" controlId="email">
                    <FormLabel>New Email</FormLabel>
                    <FormControl
                        autoFocus
                        type="email"
                        value={fields.email}
                        onChange={handleFieldChange}
                    />
                </FormGroup>

                <div className="d-grid">
                    <LoaderButton
                        type="submit"
                        variant="outline-warning"
                        isLoading={isSendingCode}
                        disabled={!validateEmailForm()}>
                        Update Email
                    </LoaderButton>
                </div>
            </form>
        );
    }

    function renderVerifyForm(){
        return(
            <form onSubmit={verifyEmail}>
                <FormGroup bsSize="large" controlId="email">
                    <FormLabel>Current Email (Unverified)</FormLabel>
                    <FormControl
                        readOnly
                        type="email"
                        placeholder={currentEmail}
                    />
                </FormGroup>

                <div className="d-grid">
                    <LoaderButton
                        type="submit"
                        variant="outline-warning"
                        isLoading={isSendingCode}>
                        Resend Verification Code
                    </LoaderButton>
                </div>
            </form>
        )
    }

    function renderConfirmationForm(){
        return(
            <form onSubmit={handleConfirmClick}>
                <FormGroup bsSize="large" controlId="code">
                    <FormLabel>Confirmation Code</FormLabel>
                    <FormControl
                        autoFocus
                        type="tel"
                        onChange={handleFieldChange}
                        value={fields.code}
                    />
                    <FormText>
                        Please check your email ({fields.email}) for the confirmation code.
                    </FormText>
                </FormGroup>

                <div className="d-grid">
                    <LoaderButton
                        type="submit"
                        variant="outline-warning"
                        isLoading={isConfirming}
                        disabled={!validateConfirmForm()}>
                        Confirm
                    </LoaderButton>
                </div>
            </form>
        );
    }

    return(
        <div className="ChangeEmail">
            {!codeSent ? 
                isEmailVerified ?
                    renderUpdateForm()
                : renderVerifyForm()
            : renderConfirmationForm()}
        </div>
    )
}