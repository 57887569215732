import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";

import { Auth } from "aws-amplify";

import { onError } from "../lib/errorLib";
import { useFormFields } from "../lib/hooksLib";

import "./ChangePassword.css";

export default function ChangePassword(){
    const nav = useNavigate();

    const [fields, handleFieldChange] = useFormFields({
        password: "",
        oldPassword: "",
        confirmPassword: ""
    });
    const [isChanging, setIsChanging] = useState(false);

    function validateForm(){
        return(
            fields.oldPassword.length > 0 &&
            fields.password.length > 0 &&
            fields.password === fields.confirmPassword
        );
    }

    async function handleChangeClick(event){
        event.preventDefault();

        setIsChanging(true);
        try{
            const currentUser = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(
                currentUser,
                fields.oldPassword,
                fields.password
            );

            nav("/settings");
        } catch(error){
            onError(error);
            setIsChanging(false);
        }
    }

    return(
        <div className="ChangePassword">
            <form onSubmit={handleChangeClick}>
                <FormGroup bsSize="large" controlId="oldPassword">
                    <FormLabel>Old Password</FormLabel>
                    <FormControl
                        type="password"
                        onChange={handleFieldChange}
                        value={fields.oldPassword}
                    />
                </FormGroup>
                <hr />
                <FormGroup bsSize="large" controlId="password">
                    <FormLabel>New Password</FormLabel>
                    <FormControl
                        type="password"
                        onChange={handleFieldChange}
                        value={fields.password}
                    />
                </FormGroup>
                <FormGroup bsSize="large" controlId="confirmPassword">
                    <FormLabel>Confirm Password</FormLabel>
                    <FormControl
                        type="password"
                        onChange={handleFieldChange}
                        value={fields.confirmPassword}
                    />
                </FormGroup>

                <div className="d-grid">
                    <LoaderButton
                        type="submit"
                        variant="outline-warning"
                        disabled={!validateForm()}
                        isLoading={isChanging}>
                        Change Password
                    </LoaderButton>
                </div>
            </form>
        </div>
    );
}