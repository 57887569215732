import React from "react";
import { Route, Routes } from "react-router-dom";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import ResetPassword from "./containers/ResetPassword";
import Settings from "./containers/Settings";
import ChangeEmail from "./containers/ChangeEmail";
import ChangePassword from "./containers/ChangePassword";

import NewNote from "./containers/NewNote";
import Notes from "./containers/Notes";

export default function Links(){
    return(
        <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/login" 
                element={
                    <UnauthenticatedRoute>
                        <Login />
                    </UnauthenticatedRoute>
                }
            />
            <Route path="/signup" 
                element={
                    <UnauthenticatedRoute>
                        <Signup />
                    </UnauthenticatedRoute>
                }
            />
            <Route path="/login/reset"
                element={
                    <UnauthenticatedRoute>
                        <ResetPassword />
                    </UnauthenticatedRoute>
                }
            />
            
            <Route path="/settings"
                element={
                    <AuthenticatedRoute>
                        <Settings />
                    </AuthenticatedRoute>
                }
            />
            <Route path="/settings/email"
                element={
                    <AuthenticatedRoute>
                        <ChangeEmail />
                    </AuthenticatedRoute>
                }
            />
            <Route path="/settings/password"
                element={
                    <AuthenticatedRoute>
                        <ChangePassword />
                    </AuthenticatedRoute>
                }
            />
            
            <Route path="/notes/new" 
                element={
                    <AuthenticatedRoute>
                        <NewNote />
                    </AuthenticatedRoute>
                }
            />
            <Route path="/notes/:id" 
                element={
                    <AuthenticatedRoute>
                        <Notes />
                    </AuthenticatedRoute>
                }
            />

            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}