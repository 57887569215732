import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";

import { Auth } from "aws-amplify";

import Routes from "./Routes"
import { AppContext } from "./lib/contextLib";
import { onError } from "./lib/errorLib";

import ErrorBoundary from "./components/ErrorBoundary";

import "./App.css";

function App(){
    const nav = useNavigate();

    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);

    React.useEffect(() => {
        onLoad();
    }, []);

    async function onLoad(){
        try{
            await Auth.currentSession();
            userHasAuthenticated(true);
        }catch(error){
            if(error !== "No current user"){
                onError(error);
            }
        }

        setIsAuthenticating(false);
    }

    async function handleLogout(){
        await Auth.signOut();
        userHasAuthenticated(false);
        nav("/login");
    }

    return(
        <div className="App container py-3">
            <Navbar collapseOnSelect bg="light" expand="md" className="mb-3 px-3">
                <LinkContainer to="/">
                    <Navbar.Brand className="fw-bold text-muted">
                        ZeroNote
                    </Navbar.Brand>
                </LinkContainer>

                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Nav activeKey={window.location.pathname}>
                        {isAuthenticated ? (
                            <>
                                <LinkContainer to="/">
                                    <Nav.Link>Home</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="/settings">
                                    <Nav.Link>Settings</Nav.Link>
                                </LinkContainer>
                                <Nav.Link onClick={handleLogout}>Log Out</Nav.Link>
                            </>
                        ) : (
                            <>
                                <LinkContainer to="/signup">
                                    <Nav.Link>Sign Up</Nav.Link>
                                </LinkContainer>

                                <LinkContainer to="/login">
                                    <Nav.Link>Log In</Nav.Link>
                                </LinkContainer>
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <ErrorBoundary>
                <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
                    <Routes />
                </AppContext.Provider>
            </ErrorBoundary>
        </div>
    );
}

export default App;
