import React from "react";

import "./NotFound.css";

export default function NotFound(){
    return(
        <div className="NotFound text-center">
            <h3>Where content?</h3>
        </div>
    );
}