const config = {
    // Max filesize for NewNote
    MAX_ATTACHMENT_SIZE: 5000000,

    // Backend config info
    s3: {
        REGION: process.env.REACT_APP_REGION,
        BUCKET: process.env.REACT_APP_BUCKET,
    },
    apiGateway: {
        REGION: process.env.REACT_APP_REGION,
        URL: process.env.REACT_APP_API_URL,
    },
    cognito: {
        REGION: process.env.REACT_APP_REGION,
        USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
        APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
        IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    },

    // Sentry config info
    SENTRY_DSN: "https://300efa34e73c4098a63aaaed8fb2ee53@o4505363730464768.ingest.sentry.io/4505363732627456",
};

export default config;