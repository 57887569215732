import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";

import { API } from "aws-amplify";

import config from "../config";
import { onError } from "../lib/errorLib";
import { useFormFields } from "../lib/hooksLib";
import { s3Upload } from "../lib/awsLib";

import "./NewNote.css";

export default function NewNote(){
    const nav = useNavigate();

    const file = useRef(null);
    const [content, setContent] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    function validateForm(){
        return content.length > 0;
    }

    function handleFileChange(event){
        file.current = event.target.files[0];
    }

    async function handleSubmit(event){
        event.preventDefault();

        if(file.current && file.current.size > config.MAX_ATTACHMENT_SIZE){
            alert(
                `Please pick a file smaller than 
                ${config.MAX_ATTACHMENT_SIZE / 1000000} MB.`
            );
            return;
        }

        setIsLoading(true);
        try{
            const attachment = file.current? await s3Upload(file.current) : null;
            await createNote({
                content,
                attachment,
            });
            nav("/");
        }catch(error){
            onError(error);
            setIsLoading(false);
        }
    }

    function createNote(noteBody){
        return API.post("notes", "/notes", {
            body: noteBody,
        });
    }

    return(
        <div className="NewNote">
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="content">
                    <Form.Control
                        value={content}
                        as="textarea"
                        onChange={(e) => setContent(e.target.value)}
                    />
                </Form.Group>

                <Form.Group className="mt-2" controlId="file">
                    <Form.Label>Attachment</Form.Label>
                    <Form.Control onChange={handleFileChange} type="file" />
                </Form.Group>

                <LoaderButton
                    block
                    type="submit"
                    variant="outline-submit"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                >
                    Create Note
                </LoaderButton>
            </Form>
        </div>
    );
}
