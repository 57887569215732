import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";

import { API } from "aws-amplify";

import config from "../config";
import { onError } from "../lib/errorLib";

import "./Settings.css";

export default function Settings(){
    const nav = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    return(
        <div className="Settings">
            <div className="d-grid gap-2">
                <LinkContainer to="/settings/email">
                    <Button 
                        variant="outline-warning">
                        Change Email
                    </Button>
                </LinkContainer>

                <LinkContainer to="/settings/password">
                    <Button 
                        variant="outline-warning">
                        Change Password
                    </Button>
                </LinkContainer>
            </div>
        </div>
    )
}