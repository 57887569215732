import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";

import { API, Storage } from "aws-amplify";

import config from "../config";
import { onError } from "../lib/errorLib";
import { s3Upload, s3Delete } from "../lib/awsLib";

import "./Notes.css";

export default function Notes(){
    const nav = useNavigate();

    const { id } = useParams();
    const file = useRef(null);

    const [note, setNote] = useState(null);
    const [content, setContent] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        function loadNote(){
            let noteAPIString = "/notes/" + id;
            return API.get("notes", noteAPIString);
        }

        async function onLoad(){
            try{
                const note = await loadNote();
                const { content, attachment } = note;

                if(attachment){
                    note.attachmentURL = await Storage.vault.get(attachment);
                }

                setContent(content);
                setNote(note);
            }catch(error){
                onError(error);
            }
        }

        onLoad();
    }, [id]);

    function validateForm(){
        return content.length > 0;
    }
    function formatFilename(str){
        return str.replace(/^\w+-/, "");
    }
    function handleFileChange(event){
        file.current = event.target.files[0];
    }

    function saveNote(noteBody){
        let noteAPIString = "/notes/" + id;
        return API.put("notes", noteAPIString, {
            body: noteBody,
        });
    }
    async function handleSubmit(event){
        event.preventDefault();

        let attachment;
        if(file.current && file.current.size > config.MAX_ATTACHMENT_SIZE){
            alert(`Please pick a file smaller than 
                ${config.MAX_ATTACHMENT_SIZE / 1000000} MB.`);
            return;
        }

        setIsLoading(true);
        try{
            if(file.current){
                if(note.attachment){
                    await s3Delete(note.attachment);
                }
                attachment = await s3Upload(file.current);
            }

            await saveNote({
                content,
                attachment: attachment || note.attachment,
            });
            nav("/");
        }catch(error){
            onError(error);
            setIsLoading(false);
        }
    }

    function deleteNote(){
        let noteAPIString = "/notes/" + id;
        return API.del("notes", noteAPIString);
    }
    async function handleDelete(event){
        event.preventDefault();

        const confirmed = window.confirm(
            "Are you sure you want to delete this note?"
        );

        if(!confirmed){
            return;
        }

        setIsDeleting(true);
        try{
            await deleteNote();
            if(note.attachment){
                await s3Delete(note.attachment);
            }
            nav("/");
        }catch(error){
            onError(error);
            setIsDeleting(false);
        }
    }

    return(
        <div className="Notes">
            {note && (
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="content">
                        <Form.Control
                            as="textarea"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mt-2" controlId="file">
                        <Form.Label>Attachment</Form.Label>
                        {note.attachment && (
                            <p>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={note.attachmentURL}
                                >
                                    {formatFilename(note.attachment)}
                                </a>
                            </p>
                        )}
                        <Form.Control onChange={handleFileChange} type="file" />
                    </Form.Group>

                    <LoaderButton
                        block="true"
                        type="submit"
                        variant="outline-submit"
                        isLoading={isLoading}
                        disabled={!validateForm()}
                    >
                        Save
                    </LoaderButton>

                    <LoaderButton
                        block="true"
                        variant="danger"
                        onClick={handleDelete}
                        isLoading={isDeleting}
                    >
                        Delete
                    </LoaderButton>
                </Form>
            )}
        </div>
    );
}